import { ChannelPagedQueryResponse } from '@commercetools/platform-sdk';
import type { NextPage } from 'next';

import { Page } from 'features/page';

interface IHomeProps {
  channels: ChannelPagedQueryResponse;
}

const Home: NextPage<IHomeProps> = () => {
  return (
    <Page>
      <h1>Home</h1>
    </Page>
  );
};

export default Home;
