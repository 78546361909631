import Link from 'next/link';
import React, { FC } from 'react';

export const Page: FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <>
      <header className="header wrap flow pad-y">
        <Link href="/">
          <strong>
            Sproutl Hub <span>🪴</span>
          </strong>
        </Link>
      </header>
      <main className="wrap flow pad-y">{children}</main>
    </>
  );
};
